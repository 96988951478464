import CreateReportPayload from "@/dto/report/CreateReportPayload";
import axios from "axios";
import ReportDTO from "@/dto/report/ReportDTO";
import {REPORT_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import ReportFilter from "@/dto/report/ReportFilter";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";
import Pageable from "@/dto/Pageable";

export default new class ReportService {

    public findReportsByFilter(filter: ReportFilter) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ['firstName', filter.firstName],
                ['lastName', filter.lastName],
                ['reportType', filter.reportType],
                ['pageSize', filter.pageSize],
                ['pageNumber', filter.pageNumber],
                ['orderField', filter.orderField],
                ['orderDirection', filter.orderDirection]));
        return axios.get<Pageable<ReportDTO>>(`${REPORT_ENDPOINT}${queryString}`, {headers: authHeader()})
    }

    public requestReport(req: CreateReportPayload) {
        return axios.post<ReportDTO>(`${REPORT_ENDPOINT}`, req, {headers: authHeader()})
    }
}