





























import {Component, Prop, Vue} from "vue-property-decorator";
import CreateReportPayload from "@/dto/report/CreateReportPayload";
import PortalInput from "@/components/common/PortalInput.vue";
import {InputType} from "@/components/common/InputType";
import SelectOption from "@/components/common/SelectOption";
import {ReportType} from "@/dto/report/ReportType";
import {ifValid} from "@/utils/ComponentUtils";
import ReportService from "@/services/ReportService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import BankAccountDTO from "@/dto/BankAccountDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import ReportDTO from "@/dto/report/ReportDTO";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");

@Component({
	computed: {
		ReportType() {
			return ReportType
		},
		SelectOption() {
			return SelectOption
		},
		InputType() {
			return InputType
		}
	},
	components: {PortalSelect, PortalInput}
})
export default class CreationReportModalComponent extends Vue {
	private request = new CreateReportPayload();

	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private callback!: () => void;

	public mounted(): void {
		console.log(ReportType.GENERAL_ACH.toString())
	}

	private buildReportTypeOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(ReportType.GENERAL_ACH).value(ReportType.GENERAL_ACH).build()
		]
	}

	private requestReport(): void {
		ifValid(this, () => {
			this.startLoading()
			this.request.requesterId = Workspaces.getCurrent.id;
			ReportService.requestReport(this.request).then(
				res => this.processResp(res.data),
				err => this.processError(err)
			)
		})
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private processResp(report: ReportDTO) {
		this.successful = true;
		Vue.prototype.$docUtils.download(report.report?.id)
		this.stopLoading();
		this.callback();
		this.$modal.hideAll();
	}
}

