































































































import {Component, Vue} from 'vue-property-decorator';
import ReportFilter from "@/dto/report/ReportFilter";
import CreationReportModalComponent from "@/components/admin/report/CreationReportModalComponent.vue";
import ReportDTO from "@/dto/report/ReportDTO";
import {ifValid} from "@/utils/ComponentUtils";
import ReportService from "@/services/ReportService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import Pageable from "@/dto/Pageable";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {ReportType} from "@/dto/report/ReportType";

const AppModule = namespace("App");

@Component({
	computed: {
		ReportFilter() {
			return ReportFilter
		}
	},
	components: {PortalSelect, PortalTextarea, PortalInput, PaginationComponent}
})
export default class ReportList extends Vue {
	private filter = new ReportFilter();

	private reports: Array<ReportDTO> = [];

	private message = "";

	private successful = false;

	private totalPages = 1;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted(): void {
		this.loadReports();
	}

	private changePage(page: number) {
		this.filter.pageNumber = page - 1;
		this.loadReports();
	}

	private openModel(): void {
		this.$modal.show(
			CreationReportModalComponent,
			{
				callback: () => this.loadReports()
			},
			{
				height: "auto"
			}
		)
	}

	private loadReports(): void {
		ifValid(this, () => {
			this.startLoading();
			ReportService.findReportsByFilter(this.filter).then(
				res => this.processResp(res.data),
				err => this.processError(err)
			)
		})
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private processResp(reports: Pageable<ReportDTO>) {
		this.successful = true;
		this.reports = reports.data
		this.totalPages = reports.countOfPages;
		this.stopLoading();
	}

	private buildReportTypeOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(ReportType.GENERAL_ACH).value(ReportType.GENERAL_ACH).build()
		]
	}
}

